"use strict";

import "../../../../web/vendor/javascript-auto-complete/auto-complete.css";
import * as jQuery from "jquery";
import * as BatmaidAutoComplete from './batmaidautocomplete.js';

const $ = jQuery;

export function init() {
    function clearZip(input) {
        return input.match(new RegExp(/(L-|l-|7)?\d{4}/))[0];
    }

    var autoCompleteConfig = {
        renderItem: function (item, search) {
            return '<div ' +
                'class="autocomplete-suggestion" ' +
                'data-zipcode="' + item[0] + '" ' +
                'data-city="' + item[1] + '" ' +
                'data-val="' + search + '">' + item[0] + ' - ' + item[1] + '</div>';
        }
    };

    $('[data-autocomplete-id]').each(function() {

        var $container = $(this);
        var zipCode = new BatmaidAutoComplete.BatmaidAutoComplete($.extend(
            {
                source: {
                    isAjax: true,
                    url: GLOBALS.urls.zipCodeSuggestions,
                    addServiceType: true,
                    loader: $container.find('div.auto-suggest-loader')
                },
                'selector': $container.find('input.zip-code-autocomplete')[0],
                'onSelect': function (e, term, item) {
                    var targetInput = $container.find('input.zip-code-autocomplete');
                    targetInput.val(item.getAttribute('data-zipcode'));
                    $(targetInput).closest('form.zip-form').submit();
                },
                cache: 0,
                delay: 0
            }, autoCompleteConfig));
    });

    $('.zip-form').submit(function(){
        var that = $(this);
        var value = that.find('input.zip-code-autocomplete').val();
        if (value.length == 0) {
            $('.form-autocomplete').addClass('has-error');
            return false;
        }
    });

    $(document).on("keypress", "input.zip-code", function (e) {
        if (e.which === 13) {
            var suggestions = $("div.autocomplete-suggestions");
            if (suggestions.is(":visible") && $("div.autocomplete-suggestion", suggestions).length > 0) {
                var firstSuggestion = $("div.autocomplete-suggestion:first", suggestions);
                var selectedSuggestion = $("div.autocomplete-suggestion.selected", suggestions);
                if (selectedSuggestion.length > 0) {
                    $(this).val(selectedSuggestion.attr('data-zipcode'));
                }
                else if (firstSuggestion.length > 0) {
                    $(this).val(firstSuggestion.attr('data-zipcode'));
                }
            }
        }
    });

    $(document).on("submit", ".zip-form", function (e) {
        var $container = $(this).closest("[data-autocomplete-id]");
        var input = $container.find('input.zip-code-autocomplete');
        var value = clearZip(input[0].value);
        input.val(clearZip(value))
        $container.closest("span.submit-booking-loader", this).removeClass("hidden");
    });

    $('.zip-code-autocomplete').focus(function(){
        $(this).parents('form').addClass('is-typing');
        $('.hideServiceSelector').val($(this).parents('.home-screen').length > 0 ? '1' : '0');
    });

    $('.zip-code-autocomplete').blur(function(){
        var that = $(this);
        var form = that.parents('form');
        if (!that.val()){
            form.removeClass('is-typing');
        }
    });
}
