// this is an extension of the global autocomplete JS lib
import * as autocomplete from '../../../../web/vendor/javascript-auto-complete/auto-complete.min.js';
import * as jQuery from "jquery";

const $ = jQuery;

export function BatmaidAutoComplete(options)
{
    var self = this;
    this.defaultConfig = {
        minChars: 1
    };
    this.autoComplete = null;
    this.xhr = null;

    function createAjaxSource(options)
    {
        return function(term, suggest) {
            options.loader.removeClass('hidden');

            try {
                self.xhr.abort();
                $(".autocomplete-suggestions ").hide();
            } catch(e){
                // do nothing
            }

            var data = { q: term };

            if (options.hasOwnProperty('addServiceType') && options.addServiceType){
                data.type =  $('.offer-button.is-active').attr('data-service-type');
            }

            self.xhr = $.getJSON(options.url, data, function(data){
                options.loader.addClass('hidden');
                suggest(data);
            });
        }
    }

    function initConfig(options)
    {
        var config = $.extend({}, self.defaultConfig);
        // check for selector
        if (options.hasOwnProperty('selector')) {
            config.selector = options.selector;
        }

        if (options.hasOwnProperty('source')) {
            if (options.source.hasOwnProperty('isAjax')) {
                config.source = createAjaxSource(options.source);
            } else {
                config.source = options.source;
            }
        }

        if (options.hasOwnProperty('renderItem')) {
            config.renderItem = options.renderItem;
        }

        if (options.hasOwnProperty('onSelect')) {
            config.onSelect = options.onSelect;
        }

        if (options.hasOwnProperty('cache')) {
            config.cache = options.cache;
        }

        if (options.hasOwnProperty('delay')) {
            config.delay = options.delay;
        }

        return config;
    }

    this.autoComplete = new autocomplete(initConfig(options));

    return this;
}

