import * as jQuery from "jquery";
import * as Cookies from "js-cookie";

const $ = jQuery;

window.jQuery = jQuery;

export function init () {
    $(document).on("click", "#close-and-accept-cookie", function () {
        acceptCookiePolicy();
    });
}

function acceptCookiePolicy () {
    $("#cookies-notification-wrapper").hide();
    Cookies.set(GLOBALS.cookiePolicyName, 1, {expires: 365, path: '/'});
}
