import $ from 'jquery';

export function init () {
    document.querySelector('#uncovered-code-modal').classList.add('is-active');

    var saveUncoveredCode = $('a#save-uncovered-code');
    var uncoveredLocationForm = $('form#uncovered-code-form');

    uncoveredLocationForm.find('input[name=email]').focus(function(){
        $('#uncovered-code-form .notification').hide();
    });

    uncoveredLocationForm.submit(function () {
        var $this = $(this);
        var statusMessage = $('#status-message');
        var loader = $('span.loader', saveUncoveredCode);
        loader.removeClass('helpster_invisible');

        $('#uncovered-code-form .notification').hide();
        $('#uncovered-code-form #status-message-fail').html('').hide();

        $.ajax({
            url: $this.attr('action'),
            type: $this.attr('method'),
            data: $this.serialize(),
            success: function (response) {
                var className = 'is-danger';
                if (response.status === 'success') {
                    className = 'is-success';
                }
                loader.addClass('helpster_invisible');
                $('#uncovered-code-form #status-message-success').show();
                $('.btn--submit.save-uncovered-code').hide();
            },
            error: function (response) {
                loader.addClass('helpster_invisible');
                if (response.responseJSON.hasOwnProperty('errors') && response.responseJSON.errors[0].message){
                    $('#uncovered-code-form #status-message-fail').html(response.responseJSON.errors[0].message).show();
                } else {
                    var defaultErrorMessage = $('#uncovered-code-form #status-message-fail').attr('data-default-content');
                    $('#uncovered-code-form #status-message-fail').html(defaultErrorMessage).show();
                }
            }
        });

        return false;
    });

    saveUncoveredCode.on('click', function () {
        $('form#uncovered-code-form').submit();
    });

    function isModalCloseButton (e) {
        return e.target.closest('#uncovered-code-modal') !== null && e.target.classList.contains('close');
    }

    document.addEventListener('click', function(e) {
        if (isModalCloseButton(e)) {
            document.querySelector('#uncovered-code-modal').classList.remove('is-active');
        }
    });
}