import "../../../Com/Helpster/CoreBundle/Resources/public/scss/pages/city-pages/city-pages.scss";
import "../../../../node_modules/slick-carousel/slick/slick.scss";
import * as autocomplete from "./autocomplete";
import $ from 'jquery';
import "slick-carousel";
import * as cookies from "./cookies";
import "./../modal/LoginModal";
import "./../modal/GiftCardModal";
import * as unCoveredZipCodeHandler from "./unCoveredZipCodeHandler";

var carouselsInitialized = false;

function initCarousels(){
    $('.slider-four-items').slick({
        dots: true,
        infinite: false,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false
                }
            }]
    });

    $('.slider-four-items-sponsors').slick({
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 4,
        autoplay: true,
        autoplaySpeed: 4000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: true
                }
            }]
    });

    $(window).resize(function () {
        $('.slider-four-items').slick('setPosition');
        $('.slider-four-items-sponsors').slick('setPosition');
    });
}

function lazyLoadLayoutItems(){

    var visibilityLineTop = $(window).scrollTop() + (1.5 * window.innerHeight);

    // trustpilot widget

    if ($('#trustpilot-widget').length && $('#trustpilot-widget').attr('data-loaded') === "0") {
        if (visibilityLineTop > $('#trustpilot-widget').offset().top) {
            $('#trustpilot-widget').attr('data-loaded', '1');
            $('#trustpilot-widget').append('<scr' + 'ipt type="text/javascript" src="https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async></scr' + 'ipt>');
        }
    }

    // carousels
    if ($('.slider').length && !carouselsInitialized) {

        if (visibilityLineTop > $('.slider:eq(0)').offset().top) {
            carouselsInitialized = true;
            $('.slider a[data-image]').each(function(){
                var that = $(this);
                that.find('img').attr('src', that.attr('data-image'));
            });
            initCarousels();
        }
    }

    // images in layout
    $('img.lazy-load').each(function(){
        var that = $(this);
        if (visibilityLineTop > that.offset().top) {
            that.attr('src', that.attr('data-image'));
            that.removeAttr('data-image');
            that.removeClass('lazy-load');
        }
    });

}

$(document).ready(function () {

    $(window).scroll(function() {
        lazyLoadLayoutItems();
    });

    lazyLoadLayoutItems();

    $('.offer-item-active .offer-button').click(function(e){
        var that = $(this);
        if (that.hasClass('not-selectable')){
            return;
        }
        e.preventDefault();
        if (!that.hasClass('is-active')) {
            $('.offer-item-active .offer-button').removeClass('is-active');
            that.addClass('is-active');
            $('form.zip-form').find('.serviceType').val(that.data('service-type'));
        }
    });

    if (typeof hasError != undefined && hasError == true) {
        unCoveredZipCodeHandler.init();
    }

    $(".js-dropdown-activate").on("click", function () {
        $(this).find(".js-dropdown").toggleClass("is-active is-hidden");
    });

    // Check for click events on the navbar burger icon
    $(".navbar-burger").click(function () {

        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        $(".navbar-burger").toggleClass("is-active");
        $(".navbar-menu").toggleClass("is-active");

    });

    $(".js-list-shops ul > li").on("click", function () {
        var $this = $(this);

        $this
            .siblings()
            .removeClass("open")
            .find("ul")
            .hide();

        $this.addClass("open");

        var nestedList = $this.find("ul");

        if (nestedList.is(":hidden")) {
            nestedList.toggle();
        }
    });

    var vidDefer = $('#video-container iframe');
    for (let i = 0; i < vidDefer.length; i++) {
        if (vidDefer[i].getAttribute('data-src')) {
            vidDefer[i].setAttribute('src', vidDefer[i].getAttribute('data-src'));
        }
    }

    autocomplete.init();
    cookies.init();

    // show covered codes links modal
    var showCoveredCodesLinks = document.querySelectorAll('.show-covered-codes');
    for (let i = 0; i < showCoveredCodesLinks.length; i++) {
        showCoveredCodesLinks[i].addEventListener('click', function (e) {
        e.preventDefault();

            if ($('#covered-codes-modal').attr('data-loaded-content') === '0') {
                var stateId = parseInt($('#covered-codes-modal').attr('data-state-id'));
                var params = stateId ? {'stateId' : stateId } : {};
                $.get($('#covered-codes-modal').attr('data-source'), params,function (response) {
                    $('#covered-codes-all-states .response-html').html(response);
                    $('#covered-codes-all-states .codes-loader-wrap').fadeOut(function(){
                        $('#covered-codes-all-states .response-html').show();
                    });
                    $('#covered-codes-modal').attr('data-loaded-content', '1');
                });
            }
            var modal = document.querySelector('#covered-codes-modal');
            var html = document.querySelector('html');
            modal.classList.add('is-active');
            html.classList.add('is-clipped');

            modal.querySelector('.modal-background').addEventListener('click', function (e) {
                e.preventDefault();
                modal.classList.remove('is-active');
                html.classList.remove('is-clipped');
            });

            modal.querySelector('.cp-modal-close').addEventListener('click', function (e) {
                e.preventDefault();
                modal.classList.remove('is-active');
                html.classList.remove('is-clipped');
            });
        });
    }

    if ($('.login-modal-main').length && getUrlParam('showlogin') == 'true') {
        document.querySelector('#login').click();
    }

    document.addEventListener('click', function (e) {
        let isNotificationCloseButton = e.target.classList.contains('delete') && e.target.parentElement.classList.contains('notification');
        if (isNotificationCloseButton) {
            e.target.parentElement.remove();
        }
    });

    $('#video-container').click(function(){
        $('.video-text').fadeOut();
        $('#yt-iframe-root').css('opacity', '1');
        $('[data-custom-play-icon]').fadeOut();
        loadYtApiScript();
        loadYtPlayer();
    });
});

function getUrlParam(name){
    var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
    if (results == null){
        return null;
    } else {
        return decodeURI(results[1]) || 0;
    }
}

function loadYtApiScript() {
    if (typeof (YT) == 'undefined' || typeof (YT.Player) == 'undefined') {
        var tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }
}

function onPlayerReady(event) {
    event.target.playVideo();
}

function loadYtPlayer() {
    window.onYouTubePlayerAPIReady = function () {
        var player = undefined;
        var videoId = $('#yt-iframe-root')[0] ? $('#yt-iframe-root')[0].dataset.videoId : null;

        player = new YT.Player('yt-iframe-root', {
            height: '390',
            width: '640',
            videoId: videoId,
            playerVars: {
                rel: 0,
                showinfo: 0,
                ecver: 2,
                modestbranding: 1,
            },
            events: {
                'onReady': onPlayerReady,
                'onStateChange': function () {
                    if (YT.PlayerState.PLAYING) {
                        hideCustomOverlay();
                    }

                    if (YT.PlayerState.ENDED) {
                        showCustomOverlay();
                    }
                }
            }
        });

        function hideCustomOverlay() {
            $('.video-text').hide();
            $('#yt-iframe-root').css('opacity', '1');
            $('[data-custom-play-icon]').hide();
        }

        function showCustomOverlay() {
            $('.video-text').show();
            $('#yt-iframe-root').css('opacity', '0');
            $('[data-custom-play-icon]').show();
        }
    };
}

window.addEventListener('load', (event) => {
    setTimeout(function () {
        $(window).trigger('resize');
    }, 2500);
});
