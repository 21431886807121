var GiftCardOrder = (function () {
    var giftCardContext = null,
        giftcardFormWrapper = null,
        giftcardErrorContainer = null,
        giftcardForm = null;

    function GiftCardOrder () {
        giftCardContext = $(".giftcard-form");
    }

    function handleSpinnerButton (e) {
        var thisElement = $(this);
        var elementToUpdate = $(this).parents(".spinner").find("input");
        var additionalValue = 0;

        if (thisElement.hasClass('add')) {
            additionalValue = 1;
        } else if (thisElement.hasClass('minus')) {
            additionalValue = -1;
        }

        if (elementToUpdate != null) {
            var oldValue = elementToUpdate.val();

            var newValue = (parseInt(oldValue, 10) + parseInt(additionalValue, 10));
            elementToUpdate.val(newValue < 0 ? 0 : newValue);
            elementToUpdate.trigger("change");
        }

        e.preventDefault();
        e.stopPropagation();
    }

    function handleValueChanged () {
        var quantityHolder = giftCardContext.find("#giftcard_form_quantity");
        var quantity = quantityHolder.val();
        var amount = giftCardContext.find("#giftcard_form_amount").val();

        if (isNaN(parseInt(quantity)) || parseInt(quantity) === 0) {
            quantityHolder.val(1);
            quantity = 1;
        }

        quantityHolder.val(parseInt(quantity));
        quantity = parseInt(quantity);

        var totalElement = giftCardContext.find(".giftcard-basket .total");
        totalElement.text(totalElement.attr("data-template").replace("%s", quantity * amount));
    }

    function initPartial () {
        handleValueChanged();
    }

    function initGiftcardForm () {
        giftcardErrorContainer = $("#giftcard-error-container", giftCardContext);

        var showErrorMessageFunc = function (msg) {
            giftCardContext.find(".alert-danger").removeClass("hidden").find("span").text(msg);
        };

        $(giftCardContext).on("change", "input", function () { handleValueChanged(); });
        $(giftCardContext).on("change", "select", function () { handleValueChanged(); });
        $(giftCardContext).on("submit", "form[name='giftcard_form']", function (e) {
            var loader = $("span.loader", giftCardContext);
            loader.removeClass('helpster_invisible');

            if (!giftcardErrorContainer.hasClass('helpster_invisible')) {
                giftcardErrorContainer.addClass('helpster_invisible');
            }

            $.ajax({
                url: $(this).attr('action'),
                data: $(this).serialize(),
                type: 'POST',
                success: function (response, status, jqXHR) {
                    if (typeof response === "object") {
                        window.location = response.url;
                    } else {
                        giftcardForm.html(response);
                        initPartial();
                    }
                },
                error: function (jqXHR, status, error) {
                    loader.addClass('helpster_invisible');
                    showErrorMessageFunc("Reuest failed");
                }
            });
            e.preventDefault();
        });
    }

    GiftCardOrder.prototype.initOrder = function () {
        giftcardFormWrapper = $("div#giftcard-wrapper", giftCardContext);
        giftcardErrorContainer = $("#giftcard-error-container", giftCardContext);
        giftcardForm = $("#giftcard_form");
        initGiftcardForm();
        handleValueChanged();
        $(giftCardContext).on('click', "div.spinner .btn", handleSpinnerButton);
    };

    return GiftCardOrder;
})();

$(function () {
    if ($('#giftcard-modal').length) {
        $('#giftcard-modal .cp-modal-close').click(function (e) {
            e.preventDefault();
            $('#giftcard-modal').removeClass('is-active');
            $('html').removeClass('is-clipped');
        });

        showForFirstTime();
        var innerGiftCardOrder = new GiftCardOrder();
        innerGiftCardOrder.initOrder();
    }
});

function showForFirstTime () {
    if (getCookie("giftcard_shown") == 1) {
        return;
    }
    showGiftcardForm();
}

function showGiftcardForm () {
    setCookie('giftcard_shown', '1', 7);
    $('#giftcard-modal').addClass('is-active');
    $('html').addClass('is-clipped');
    setTimeout(function () {
        $('#giftcard_form_firstName').focus();
    }, 500);
}

function setCookie (cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie (cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}
