$(function () {

    function initExponeaLogin(humanId){
        window.dataLayer.push({
            event: $('body').attr('data-origin') + '-login-state-changed',
            isUserLoggedIn: 'true',
            humanId: humanId,
        });
    }

    function initExponeaLogout(){
        window.dataLayer.push({
            event: $('body').attr('data-origin') + '-login-state-changed',
            isUserLoggedIn: 'false'
        });
    }

    $('body').on('focus', '.with-animated-labels input[type=text], .with-animated-labels input[type=password]', function (e) {
        $(this).parent().addClass('is-typing');
    });

    $('.button-logout').click(function(){
        initExponeaLogout();
    });

    $('body').on('blur', '.with-animated-labels input[type=text], .with-animated-labels input[type=password]', function (e) {
        var that = $(this);
        if (!that.val()) {
            that.parent().removeClass('is-typing');
        }
    });

    $('body').on('click', '.login-show-password', function (e) {
        e.preventDefault();
        $('.input-wrap-password').addClass('password-visible');
        $('.input-wrap-password input').attr('type', 'text');
    });

    $('body').on('click', '.login-hide-password', function (e) {
        e.preventDefault();
        $('.input-wrap-password').removeClass('password-visible');
        $('.input-wrap-password input').attr('type', 'password');
    });

    $('body').on('click', '.checkbox-replace', function (e) {
        $('#rememberMeCheckbox').prop("checked", !$('#rememberMeCheckbox').prop("checked"));
        $('#rememberMeCheckbox').change();
    });

    $('body').on('change', '#rememberMeCheckbox', function (e) {
        var that = $(this);
        $('.remember-me-wrap').removeClass('checked');
        if (that.is(':checked')) {
            $('.remember-me-wrap').addClass('checked');
        }
    });

    $('body').on('click', '.login-modal-wrap', function (e) {
        var target = $(e.target);
        if (target.is('.login-modal-wrap')) {
            $('.login-modal:visible .login-modal-close').click();
        }
    });

    $('body').on('click', '.login-modal-close', function (e) {
        e.preventDefault();
        $('.login-overlay').fadeOut(function () {
            $('.login-overlay').remove();
        });

        $('.login-modal:visible').animate({'margin-top': '-70px', 'opacity': '0'}, 200, function () {
            $('.login-modal-wrap').addClass('helpster_invisible');
        })
    });

    $('body').on('click', '.login-modal-button-back-to-login', function (e) {
        e.preventDefault();
        $('.login-modal-password').fadeOut('fast', function () {
            $('.login-modal-main').fadeIn('fast');
        });
    });

    $('body').on('click', '.forgot-password-label', function (e) {
        e.preventDefault();
        $('.login-modal-password .form-login-info').hide();
        $('.login-modal-main').fadeOut('fast', function () {
            $('.login-modal-password').fadeIn('fast');
        });
    });

    $('body').on('click', '.box-modal-close', function (e) {
        e.preventDefault();
        $('.form-login-retry-info').fadeOut();
    });

    $('body').on('click', '.show-login-form', function (e) {
        e.preventDefault();
        $('.form-login-error').hide();
        $('body').prepend('<div class="login-overlay"></div>');
        $('.login-modal-wrap').removeClass('helpster_invisible');
        $('.login-modal-main').show();
        $('.login-modal-password').hide();
        $('.login-modal-main').css({'margin-top': '-70px', 'opacity': '0'});
        $('.login-modal-main').animate({'margin-top': '0px', 'opacity': '1'}, 200)
    });

    $('body').on('submit', '.login-modal-password form', function (e) {
        e.preventDefault();
        var that = $(this);
        $('.login-modal-password').addClass('is-loading');
        $('.login-modal-password .form-login-info, .login-modal-password .form-login-server-error').hide();
        $.ajax({
            url: that.attr('action'),
            type: 'POST',
            data: {
                'email': $('.login-modal-password input[name=email]').val()
            },
            success: function (response) {
                $('.login-modal-password .form-login-info').html(response.message).fadeIn();
                $('.login-modal-password input[name=email]').val('');
            },
            error: function () {
                $('.login-modal-password .form-login-server-error').fadeIn();
            },
            complete: function () {
                $('.login-modal-password').removeClass('is-loading');
            }
        }, 'json');
    });

    $('body').on('submit', '.login-modal-main form', function (e) {
        var that = $(this);
        e.preventDefault();

        var username = $('.login-modal-main input[name=username]').val();
        var password = $('.login-modal-main input[name=password]').val();

        $('.login-modal-main').addClass('is-loading');
        $('.login-modal-main .form-login-error, .login-modal-main .form-login-server-error').hide();

        $.ajax({
            url: that.attr('action'),
            type: 'POST',
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify({
                'username': username,
                'password': password,
                'rememberMe': $('.login-modal-main input[name=rememberMe]').is(':checked'),
            }),
            success: function (response) {
                if (typeof response.retry != 'undefined') {
                    $('.form-login-retry-info span').html(response.data.message);
                    $('.form-login-retry-info').show();
                } else {
                    $.get($('form.modal-login-form').attr('data-url-whoami'), function(response){
                        initExponeaLogin(response.humanId);
                        for (var role in response.roles){
                            if (response.roles[role] == 'ROLE_ADMIN'){
                                location.href = $('form.modal-login-form').attr('data-url-admin');
                                return;
                            }
                            if (response.roles[role] == 'ROLE_CLIENT'){
                                location.href = $('form.modal-login-form').attr('data-url-client');
                                return;
                            }
                            if (response.roles[role] == 'ROLE_AGENT'){
                                location.href = $('form.modal-login-form').attr('data-url-agent');
                                return;
                            }
                        }
                        location.href = '/';
                    });
                }
            },
            error: function (response) {
                if (typeof response.responseJSON.message != 'undefined') {
                    $('.login-modal-main .form-login-error').html(response.responseJSON.message).fadeIn();
                } else if (typeof response.responseJSON.errors != 'undefined') {
                    $('.login-modal-main .form-login-error').html(response.responseJSON.errors[0].message).fadeIn();
                } else {
                    $('.login-modal-main .form-login-server-error').fadeIn();
                }
            },
            complete: function () {
                $('.login-modal-main').removeClass('is-loading');
            }
        }, 'json');
    });
});

